import { EdiQualifierType } from '@hq-app/user-management/models/edi-qualifier';
import { Company } from './company';
import { IndustryVertical } from '@hq-core/models/industry-vertical';

export enum SupplierType {
    Distributor = 1,
    DirectManufacturer = 2,
    DistributedManufacturer = 3,
    DirectAndDistributedManufacturer = 4,
}

export enum SubscriptionType {
    PublicRequired = 1,
    PublicOptional = 2,
    PrivateOptional = 3,
    PrivateRequired = 4,
}

export class OrderSubmissionMethod {
    vendorId: number;
    sourceId: number;
    submissionId: number;

    constructor(options?: Partial<OrderSubmissionMethod>) {
        Object.assign(this, options);
    }
}

export class LegacyVendor extends Company {
    externalVendorId: string;
    freeShippingAmount: number;
    minimumOrderAmount: number;
    parentId?: number;
    privateCatalog: boolean;
    memoAllowed?: boolean;
    partitionEnabled?: boolean;
    senderId?: string;
    receiverId?: string;
    submissionType?: number;
    supplierType?: SupplierType;
    interchangeIdQualifier: EdiQualifierType;
    subscriptionType: SubscriptionType;
    isAdvertiser: boolean;
    division: string;
    notes: string;
    isUsNaturalProducts: boolean;
    detaSyncCustomerId: number;
    lastDataUpdateDate: Date;
    orderFee: number;
    orderFeeStartDate: Date;
    ftpAddress: string;
    ftpDirectory: string;
    supplierPortalEnabled: boolean;
    newVendorId: number;
    legacyId: number;
    babelwayOrderGateway: string;
    connectionMethod: string;
    exchangeFileType: string;
    vanEdiProvider: string;
    salesForceId: number;
    industryVerticals: Array<IndustryVertical>;
    productIdentifier: number;
    hasAccountNumber: boolean;
    catalogDataSourceId: number;
    salesGenius: boolean;

    constructor(options?: Partial<LegacyVendor>) {
        super(options);
        Object.assign(this, options);
    }
}
